@charset "UTF-8";



/*?��?��?��?�� : ?��?��*/
@font-face {
    font-family: 'NanumBarunGothic';
    font-weight: 400;
    src: local("?��?��바른고딕"),
         url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.woff2) format('woff2'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.woff) format('woff'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.ttf) format('truetype');
   }
   @font-face {
    font-family: 'NanumBarunGothic';
    font-weight: 700;
    src: local("?��?��바른고딕 Bold"),
         url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.woff2) format('woff2'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.woff) format('woff'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.ttf) format('truetype');
   }
   @font-face {
    font-family: 'NanumBarunGothic';
    font-weight: 300;
    src: local("?��?��바른고딕 Light"),
         url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.woff2) format('woff2'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.woff) format('woff'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.ttf) format('truetype');
   }
   @font-face {
    font-family: 'NanumBarunGothic';
    font-weight: 200;
    src: local("?��?��바른고딕 UltraLight"),
         url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.woff2) format('woff2'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.woff) format('woff'),
         url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.ttf) format('truetype');
   }


/*@font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    font-display: swap;
    src: local('Pretendard Black'), url('../fonts/pretendard/Pretendard-Black.subset.woff2') format('woff2'), url('./woff-subset/Pretendard-Black.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-display: swap;
    src: local('Pretendard ExtraBold'), url('../fonts/pretendard/Pretendard-ExtraBold.subset.woff2') format('woff2'), url('./woff-subset/Pretendard-ExtraBold.woff') format('woff');
}*/

/*------------------------------------------------
@font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-display: swap;
    src: local('Pretendard Bold'), url('../fonts/pretendard/Pretendard-Bold.subset.woff2') format('woff2'), url('../fonts/pretendard/Pretendard-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-display: swap;
    src: local('Pretendard SemiBold'), url('../fonts/pretendard/Pretendard-SemiBold.subset.woff2') format('woff2'), url('../fonts/pretendard/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-display: swap;
    src: local('Pretendard Medium'), url('../fonts/pretendard/Pretendard-Medium.subset.woff2') format('woff2'), url('../fonts/pretendard/Pretendard-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src: local('Pretendard Regular'), url('../fonts/pretendard/Pretendard-Regular.subset.woff2') format('woff2'), url('../fonts/pretendard/Pretendard-Regular.woff') format('woff');
}
--------------------------------------------------*/


/*@font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-display: swap;
    src: local('Pretendard Light'), url('../fonts/pretendard/Pretendard-Light.subset.woff2') format('woff2'), url('./woff-subset/Pretendard-Light.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    font-display: swap;
    src: local('Pretendard ExtraLight'), url('../fonts/pretendard/Pretendard-ExtraLight.subset.woff2') format('woff2'), url('./woff-subset/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    font-display: swap;
    src: local('Pretendard Thin'), url('../fonts/pretendard/Pretendard-Thin.subset.woff2') format('woff2'), url('./woff-subset/Pretendard-Thin.woff') format('woff');
}*/
/*?��?��?��?�� : ?��*/


/*기�?? ?��?�� : ?��?�� */
html{
    -webkit-text-size-adjust:none;
    -ms-user-select:none;
    -khtml-user-select:none;
    -webkit-user-select:none;
    user-select:none;

    -webkit-touch-callout:none;
    
}
    
*{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
    
body,a,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,tr,th,td,
form,fieldset,label,legend,input,textarea,button,menu{
    margin:0px;padding:0px;
    -webkit-text-size-adjust:none;
}
    
body,input,textarea,select,table,button,code{
    color:#111;
    font-family: 'NanumBarunGothic';
    font-weight: 400;
    text-align: left;
}
     
body{
    width:100%;
    position:relative;word-wrap:break-word;word-break:break-all;
    min-width:280px;
    min-height: 100vh;
    background: #efefef/*f5f5f5*/;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

/*?��?��롤바*/
/*body::-webkit-scrollbar {width: 15px;}
body::-webkit-scrollbar-thumb {
background-color: #82bbf0;
border-radius: 0px;
background-clip: padding-box;
border-left: 0px solid transparent;}
body::-webkit-scrollbar-track {
background-color: #d8d8d8;
border-radius: 0px;*/
/*box-shadow: inset 0px 0px 5px white;*/
    
nav,menu,ul,li,ol{
    list-style:none;
}
    
div{
    display:block;
}
    
table{
    border-collapse:collapse;
    border-spacing:0;
}
    
img,fieldset{
    border:none;
}
    
em,address{
    font-style:normal;
}
    
select{
    cursor: pointer;
}
    
section,article,header,footer,nav,aside,hgroup{
    display:block;
}

select::-ms-expand { 
  display: none;
}
select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}
    
button{
    border:none;
    background:none;
    cursor:pointer !important;
}
    
button:focus{
    outline:none !important;
}
    
a{
    margin:0px;
    padding:0px;
    text-decoration:none;
    cursor:pointer;
}
    
a:hover, a:active{
    text-decoration:none !important;
}
    
table{
    table-layout: fixed;
}
    
.clearFix:after{
    content:"";
    display:block;
    clear:both;
}
    
.ibFix{
    line-height:0px;
}
    
.hiddenFix{
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    line-height: 0;
    text-indent:-10000px;
}
    
caption{
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    line-height: 0;
    text-indent:-10000px;
}
    
.floatLeft{
    float: left;
}

.floatLeft:after{
    content:"";
    display:block;
    clear:both;
}
    
.floatRight{
    float: right;
    position: relative;
}

.floatRight:after{
    content:"";
    display:block;
    clear:both;
}
    
.pageFix{
    overflow: hidden;
}
    
.tCenter{
    text-align: center;
}
    
.inside{
    display: inline-block;
}
    
.inside:after{
    content: "";
    display: block;
    clear: both;
}

.row{
    display: block;
    width: 100%;
}

.row:after{
    content: "";
    display: block;
    clear: both;
}

/* .bold{font-weight: 700;} */

select {
   -webkit-appearance:none; /* for chrome */
   -moz-appearance:none; /*for firefox*/
   appearance:none;
}

select::-ms-expand{
   display:none;/*for IE10,11*/
}

.gotoTop{
    position: fixed;
    display: none;
    z-index: 100;
    width: 50px;
    height: 50px;
    background: url(../images/go_top.png) center no-repeat;
    background-size: 50px 50px;
    bottom: 76px;
    right: 20px;
    filter : drop-shadow(0 3px 6px rgba(0,0,0,0.15));
}

.gotoTop2{
    bottom : 16px;
}

.bottomFixedButton{
    position: fixed;
    z-index: 100;
    display: block;
    width: 170px;
    height: 38px;
    bottom:81px;
    /* left: calc(50% - 85px); */
    left: 50%;
    transform:translateX(-50%);
    line-height: 38px;
    border-radius: 19px;
    text-align: center;
    color:#fff !important;
    font-size: 16px;
    font-weight: 500;
    background: rgba(0,100,255,0.9);
}

.bottomFixedButton.color2{
    background: rgba(0,37,95,0.9);
    box-shadow: 0 0 4px rgba(255,255,255,0.2);
}

/*기�?? ?��?�� : ?��*/





:root{
    --main:#0064FF;
    --red:#FF2828;
    --darkBlue:#00307A;
    --borderDarkBlue:#02378A;

    --green : #009688;

    /* 2024.04.05 추�?? */
    --char-w: 1ch;
    --gap: .5*var(--char-w);
    --n-char: 7;
    --in-w: var(--n-char)*(var(--char-w) + var(--gap));
}



/*?��?��?��?�� : ?��?��*/
.centerWrap{
    display: block;
    width: 100%;
    max-width: 640px;
    padding:0 20px;
    margin:0 auto;
    position: relative;
}

.centerWrap:after{content: ""; display: block; clear: both;}

.contents{
    display: block;
    width: 100%;
}

.contents > .centerWrap{padding: 80px 20px 100px}

.centerWrap > .sample{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    padding:150px 0;
}
/*?��?��?��?�� : ?��*/





/*?��?���? : ?��?��*/
.inputNormal{
    display: block;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #DEDEDE;
    padding:0 17px;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
}

.dataWrap .bw{
    display: block;

    height: 44px;
    line-height: 44px;
    float: left;
    width: 18px;
    text-align: center;
    font-size: 12px;
    color:#555;
    font-weight: 500;
}

/*.inputNormal1in4{width:calc((100% - 60px) / 4); float: left;}*/
.inputNormal1in4{width:83px; float:left;}
.inputNormal1in2{width:calc((100% - 20px) / 2); float: left;}

.inputNormal1in4 + .inputNormal1in4{margin:0 0 0 4px}

.inputNormal::placeholder{color:#BBBBBB}

.inputNormal[readonly]{background: #F3F3F3;}

.selectNormal{
    display: block;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #DEDEDE;
    padding:0 17px;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    background: #fff url(../images/select-tri2.png) calc(100% - 17px) no-repeat;
    background-size: 8px 5px;
}

.selectNormal option{color:#111;}


.brisakInput{
    display: block;
    float: left;
    position: relative;
    cursor: pointer;
}

.brisakInput:after{content: ""; display: block; clear: both;}

.brisakInput .wrap{
    display: block;
    width: 100%;
}

.brisakInput .wrap:after{content: ""; display: block; clear: both;}


.brisakInput input{
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}

.brisakInput .icon{
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    background: url(../images/check-s004.png) center no-repeat;
    background-size: 20px 20px;
}

.brisakInput input[type="checkbox"]:checked + .wrap .icon{
    background: url(../images/check-s003.png) center no-repeat;
    background-size: 20px 20px;
}

.brisakInput input[type="radio"] + .wrap .icon{
    background: url(../images/check-004.png) center no-repeat;
    background-size: 20px 20px;
}

.brisakInput input[type="radio"]:checked + .wrap .icon{
    background: url(../images/radio_on.png) center no-repeat;
    background-size: 20px 20px;
}

.brisakInput .txt{
    display: block;
    float: left;
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
    max-width: calc(100% - 27px);
    margin:0 0 0 7px;
}

.wrapB1 .brisakInput .txt{
    font-size:16px;
    font-weight: 500;
}
/*.brisakInput input:checked + .wrap .txt{color:var(--main) !important}*/
/*?��?���? : ?��*/





/*버튼�? : ?��?��*/
.buttonDark{
    display: block;
    height: 44px;
    line-height: 42px;
    border:1px solid var(--main);
    border-radius: 8px;
    background: var(--main);
    color:#fff;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.buttonDone{
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: var(--main);
    color:#fff;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
}

.buttonDone.disabledBtn {
    background-color: grey;
}

.buttonCancel{
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #000;
    color:#fff;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
}
/*버튼�? : ?��*/




/*모달�? : ?��?��*/
.modal{
    display: none;
    width: calc(100% - 40px);
    max-width: 320px;
    text-align: center;
    top:50%;
    left: 50%;
    position: fixed;
    z-index: 1000;
    transform: translate(-50%, -50%);
}

.modal.bigModal{
    max-width: 540px;
    max-height: calc(100vh - 40px);
}

.modal.midModal{
    max-width: 440px;
}

.modal.active{display: block;}

.modalNoHeader{
    display: block;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: #fff;
    text-align: center;
    padding:30px 20px 30px;
}

.img3{
    display: block;
    width: 150px;
    margin:2px auto 20px;
    border-radius: 32px;
}

.modalTxt1{
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
}

.modalTxt1 > .bold{
    /* font-weight: 700; */
    color:var(--main);
}

.modal .modalButtons{
    display: block;
    position: relative;
    width: 100%;
}

.modal .modalButtons > .inside{
    display:block;
    width: 100%;
}

.modal .modalButtons button {
    border-radius: 0 0 10px 10px;
    float: left;
    height: 54px;
    line-height: 54px;
}

.modal .modalButtons button.button1in2 {
    width: 50% !important;
    border-radius: 0 0 0 10px !important;
}

.modal .modalButtons button.button1in2 + .button1in2 {
    border-radius: 0 0 10px 0 !important;
}

.alertImg1{
    width: 40px;
    height: 40px;
    margin:0 0 15px;
}


.modalHeader{
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 10px 10px 0 0;
    text-align: center;
    height: 60px;
    line-height: 60px;
    position: relative;
}

.modalHeader:after{content: ""; display: block; clear: both;}

.modalHeader > .modalTitle{
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
@media (max-width: 414px) {
    .modalHeader > .modalTitle {
        font-size: 16px;       
    }
}
@media (max-width: 389px) {
    .modalHeader > .modalTitle {
        font-size: 14px;
    }
}

.modalHeader > .closeModal{
    display: block;
    position: absolute;
    width: 42px;
    height: 60px;
    background: url(../images/close-261.png) center no-repeat;
    background-size: 14px 14px;
    top:0;
    right: 0;
}

.modalContent{
    display: block;
    width: 100%;
    background: #F0F0F0;
    padding:30px 17px 40px;
    /*padding:30px 30px 40px 17px;*/
    text-align: left;
    overflow-y: auto;
    max-height:calc(100vh - 154px);
}

.modalContent2{
    display: block;
    width: 100%;
    background: #F0F0F0;
    padding:30px 20px 40px 20px;
    border-radius: 0 0 10px 10px;
    text-align: left;
    overflow-y: auto;
    max-height:calc(100vh - 154px);
}

.dimm{
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 900;
    background: rgba(0,0,0,0.5);
}

.dimm.active{display: block;}
/*모달�? : ?��*/






/*?��?�� : ?��?��*/
.topBigLogo{
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    background: #000;    
    height: 60px;
    text-align: center;
    top: 0;
    left: 0;
    min-width: 280px;
    transition: box-shadow 0.3s;
    padding:5px 0 0;
}

.topBigLogo img{
    display: block;
    width: auto;
    height: 42px;
}

.topBigLogo + header{top:60px}

.topBigLogo + header + .contents > .centerWrap {
    padding: 180px 20px 100px;
}


header{
    display: block;
    width: 100%;
    position: fixed;
    z-index: 100;
    background: #fff;    
    height: 60px;
    text-align: center;
    top: 0;
    left: 0;
    min-width: 280px;
    transition: box-shadow 0.3s;
    box-shadow: 0 0 0 rgba(0,0,0,0);
}


header.active{
    transition: box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

header h1{
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color:#111;
    font-size: 20px;
    font-weight: 600;
}

header .back{
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    left: 0;
    background: url(../images/btn-prev-a1.png) 20px center no-repeat;
    background-size: 8px 14px;
}

header .appicon{
    position: absolute;
    width: 36px;
    height: 36px;
    top: 12px;
    right: 20px;
    background: url(../images/appicon192.png) center no-repeat;
    background-size: 36px 36px;
    border-radius: 7px;
}
/*?��?�� : ?��*/






/*메인 : ?��?��*/
.homeHeader{
    display: block;
    width: 100%;
    height: 50px;
    background: #efefef/*EBEBEB*/;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: 0 0 0 rgba(0,0,0,0);
}

.homeHeader.active{
    background: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: background 0.3s, box-shadow 0.3s;
}

.homeHeader .homeLogo{
    float: left;
    display: block;
    width: 160px;
    line-height: 0;
    margin:17px 0 0;
}

.homeHeader .homeLogo > img{
    display: block;
    width: 100%;
}

.homeHeader .selfStorage{
    display: block;
    float: left;
    font-size: 11px;
    color:#111 !important;
    text-align: left;
    font-weight: 500;
    background: #fff;
    height: 18px;
    line-height: 18px;
    padding:0 8px;
    border-radius: 9px;
    margin:16px 6px 0 0;
    transition: background 0.3s;
}

.homeHeader.active .selfStorage{
    background: #eaeaea;
    transition: background 0.3s;
}

.homeHeader .qmark{
    display: block;
    float: left;
    width: 30px;
    height: 50px;
    background: url(../images/qmark.png) center no-repeat;
    background-size: 18px 18px;
}

.homeHeader .bell{
    display: block;
    position: relative;
    float: left;
    width: 30px;
    height: 49px;
    background: url(../images/top-bell3.png) center no-repeat;
    background-size: 21px 23px;
}

.homeHeader .bell > .alarm{
    position: absolute;
    width: 4px;
    height: 4px;
    background: #ff2828;
    border-radius: 50%;
    top:13px;
    right: 2px;
    font-size: 0;
}

.mainContents > .centerWrap{padding:50px 20px 100px !important;}

.mainTopWrap{
    display: block;
    width: 100%;
    position: relative;
}

.mainSwiper{
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.mainSwiper .img1{
    display: block;
    width: 100%;
    border-radius: 10px;
}

.mainSwiper .txtWrap{
    position: absolute;
    top:50%;
    width: 100%;
    left:0;
    text-align: center;
    transform: translateY(-60%);
}

.mainSwiper .txtWrap > .txt1{
    color:#fff;
    font-size: 23px;
    line-height: 32px;
    font-weight: 700;
}

.mainSwiper .txtWrap > .txt2{
    color:#fff;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin:5px 0 0;
}

.mainSwiper .txtWrap > .txt3{
    color:rgba(255,255, 255, 0.7);
    font-size: 15px;
    line-height: 23px;
    font-weight: 600;
    margin : 7px 0 0;
}

.buttonSwiperPrev{
    display: block;
    position: absolute;
    z-index: 10;
    width: 40px;
    height: 48px;
    top:calc(50% - 24px);
    left: 0;
    background: url(../images/p-prev2w.png) center no-repeat;
    background-size: 12px 24px;
}

.buttonSwiperNext{
    display: block;
    position: absolute;
    z-index: 10;
    width: 40px;
    height: 48px;
    top:calc(50% - 24px);
    right: 0;
    background: url(../images/p-prev2w2.png) center no-repeat;
    background-size: 12px 24px;
}

.mainSwiper .swiper-pagination{
    display: block;
    width: 100%;
    position: absolute;
    bottom:34px !important;
    left:0;
}

.mainSwiper .swiper-pagination-bullet{
    width: 28px !important;
    height: 4px !important;
    border-radius: 2px !important;
    background: #fff !important;
}

.mainTopMenus{
    display: block;
    width: calc(100% - 30px);
    left:15px;
    bottom:-46px;
    height: 46px;
    line-height: 44px;
    border-radius: 10px;
    /* border:1px solid #E4E4E4; 
    background: #fff; */
    position: absolute;
    z-index: 50;
    padding-top: 10px;
}

.mainTopMenus:after{content: ""; display: block; clear: both;}

/* .mainTopMenus .mainTopMenu{
    float: left;
    width: 50%;
    text-align: center;
    height: 44px;
    line-height: 44px;
    color:#111 !important;
    font-weight: 700;
    font-size: 15px;
    position: relative;
} */

.mainTopMenus .mainTopMenu{
    float: left;
    width: calc(50% - 3px);
    text-align: center;
    height: 44px;
    line-height: 44px;
    color:#111 !important;
    font-weight: 700;
    font-size: 15px;
    position: relative;
    background: #fff;
    border-radius: 5px;
    border:1px solid #d0d0d0;
}

/*.mainTopMenus .mainTopMenu + .mainTopMenu:before{
    content: "";
    position: absolute;
    width: 1px;
    height: 17px;
    background: #E0E0E0;
    left:0;
    top: 13px;
}*/

.mainTopMenus .mainTopMenu + .mainTopMenu{margin:0 0 0 6px}

.mainTxt1{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding:83px 0 26px;
}

.services{
    display: block;
    width: 100%;
}

.services .service{
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    border: 1px solid #E4E4E4;
    border-radius: 10px; 
    padding:14px;
    box-shadow: 0 2px 6px #dedede;
}

.services .service > .best{
    display: block;
    width: 50px;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    text-align: center;
    color:#fff;
    font-size: 10px;
    font-weight: 700;
    /* background: var(--main); */
    background: rgb(0 190 110);
    position: absolute;
    top:-11px;
    left:14px;
}

.services .service:after{content: ""; display: block; clear: both;}

.services .service .col1{
    float: left;
    width: 100px;
    position: relative;
    line-height: 0;
}


.services .service .col1 .imgItem{
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.services .service .col2{
    float: right;
    width: calc(100% - 114px);
    position: relative;
}

.services .service .col2 > .txt1{
    display: block;
    width: 100%;
    color:#111 !important;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.services .service .col2 > .txt2{
    display: block;
    width: 100%;
    color:#6A6A6A !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;    
    margin:4px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical
}

.services .service .col2 > .originalPrice{
    display: block;
    width: 100%;
    color:#C4C4C4 !important;
    font-weight: 400;
    font-size: 11px;
    text-decoration: line-through;
    margin:4px 0 0;
}

.services .service .col2 > .originalPrice + .row{
    margin:5px 0 0;
}

.services .service .col2 .discount{
    display: block;
    position: relative;
    float: left;
    font-size: 16px;
    color:#ff2828 !important;
    text-align: left;
    font-weight: 700;
    padding: 0 8px 0 0;
}

.services .service .col2 .discount .hide{
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
}


.services .service .col2 .price{
    display: block;
    position: relative;
    float: left;
    font-size: 16px;
    color:#111 !important;
    text-align: left;
    font-weight: 700;
    padding: 0 8px 0 0;
}

.services .service > .col2 .icon1{
    position: absolute;
    background: url(../images/sv-icon1.png) center no-repeat;
    background-size: 3px 13px;
    width: 3px;
    height: 13px;
    top:1px;
    right: 0;
}

.services .service + .service{margin:16px 0 0}
/*메인 : ?��*/


/*메인메뉴 (?��?�� 고정) : ?��?��*/
.mainMenus{
    display: block;
    width: 100%;
    min-width: 280px;
    position: fixed;
    z-index: 100;
    border-radius: 15px 15px 0 0;
    height: 66px;
    left: 0;
    bottom:0;
    background: #fff;
    box-shadow: 0 -4px 6px rgba(0,0,0,0.1);
}

.mainMenu{
    float: left;
    width: 20%;
    text-align: center;
    height: 66px;
}

.mainMenu .iconM{
    width: 30px;
    height: 30px;
    margin:10px auto 0;
    opacity: 0.25;
}

.mainMenu.active .iconM{opacity: 1;}

.mainMenu .iconL{
    width: 45px;
    height: 45px;
    margin:10px auto 0;
    background: url(../images/appicon192.png) center no-repeat;
    background-size: 45px 45px;
    border-radius: 6px;
}

.mainMenu:nth-child(1) .iconM{
    background: url(../images/home.png) center no-repeat;
    background-size: 30px 30px;
}

.mainMenu:nth-child(2) .iconM{
    background: url(../images/delivery_truck.png) center no-repeat;
    background-size: 30px 30px;
}

.mainMenu:nth-child(4) .iconM{
    background: url(../images/document.png) center no-repeat;
    background-size: 30px 30px;
}

.mainMenu:nth-child(5) .iconM{
    background: url(../images/square.png) center no-repeat;
    background-size: 30px 30px;
}

.mainMenu .txtM{
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color:#B5B5B5 !important;
    margin:2px 0 0;
}

.mainMenu.active .txtM{
    font-weight: 600;
    color:#111 !important;
}

.mainMenu{}
/*메인메뉴 (?��?�� 고정) : ?��*/





/*로그?�� : ?��?��*/
.loginBody{background: var(--main);}

.login{
    display: block;
    width: calc(100% - 40px);
    max-width: 320px;
    text-align: center;
    padding:154px 0 0;
    margin:0 auto;
}

.login.new{padding:0; max-width: 100%; width:100%; padding-top: 40px;}

.login:after{content: ""; display: block; clear: both;}

.login > .logo{
    width:230px;
}


.topLogoWrap{
    display: block;
    width: 100%;
    background: #000;
    /* height: 60px; */
    padding:8px 0 0;
}

.login > .logo.new{
    height:42px;
    width: auto;
}


.login > .txt1{
    color:#fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin:38px 0 49px;
    filter : drop-shadow(0 1px 5px rgba(0,0,0,0.3));
}

.login .centerLogo{
    display: block;
    /* margin:130px auto 40px; */
    position: absolute;
    /*bottom:350px;*/
    top:calc(50% - 100px);
    left:50%;
    transform: translateX(-50%);
    max-width: 240px;
}

.login > .button0{
    display: block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    text-align: center;
    color:#fff !important;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    margin:0 0 20px;
    bottom:210px;
    position: absolute;
}

.login > .button0.new {
    text-decoration: none;
    font-size: 20px;
}

.login > .button1{
    display: block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    text-align: center;
    background: #fff;
    color:#111 !important;
    font-size: 16px;
    font-weight: 600;
}

.login > .button1.new{
    display: block;
    width: calc(100% - 50px);
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    text-align: center;
    background: #000;
    color:#fff !important;
    /* font-size: 16px; */
    font-size: 25px;
    font-weight: 600;
    margin:0 auto;
    position: absolute;
    bottom:170px;
    left:25px;
}

.login > .button2.new{
    display: block;
    width: calc(100% - 50px);
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    text-align: center;
    background: #000;
    color:#fff !important;
    /* font-size: 16px; */
    font-size: 25px;
    font-weight: 600;
    margin:0 auto;
    position: absolute;
    bottom:110px;
    left:25px;
}


.login > .button2{
    display: block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    text-align: center;
    background: var(--darkBlue);
    color:#fff !important;
    font-size: 16px;
    font-weight: 600;
    margin:18px 0 0;
}

.login > .line1{
    display: block;
    position: relative;
    width: 100%;
    height: 1px;
    background: rgba(255,255,255,0.3);
    margin:55px 0 53px;
}

.login > .line1 > .intro1{
    width: 54px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    left: calc(50% - 27px);
    top:-15px;
    font-size: 14px;
    color:#fff;
    font-weight: 500;
    z-index: 10;
    background: var(--main);
}


.login > .snsLogins{
    display: none;
    width: 100%;
    text-align: center;

    margin:30px 0 0;
}

.login > .snsLogins.active{display: block;}

.login > .snsLogins .snsLogin{
    display: block;
    float: left;
    width: 40px;
    height: 40px;
}

.login > .snsLogins .snsLogin + .snsLogin{margin:0 0 0 15px}

.login > .snsLogins .snsLogin.google{
    background: url(../images/google.png) center no-repeat;
    background-size: 40px 40px;
}

.login > .snsLogins .snsLogin.kakao{
    background: url(../images/kakao.png) center no-repeat;
    background-size: 40px 40px;
}

.login > .snsLogins .snsLogin.apple{
    background: url(../images/apple.png) center no-repeat;
    background-size: 40px 40px;
}

.login > .snsLogins .snsLogin.facebook{
    background: url(../images/facebook.png) center no-repeat;
    background-size: 40px 40px;
}

.login > .snsLogins .snsLogin.naver{
    background: url(../images/naver.png) center no-repeat;
    background-size: 40px 40px;
}

.loginBody .bgBottom{
    width: 100%;
    display: block;
    position: absolute;
    left:0;
    bottom:0px;
    height: 100px;
    background: url(../images/bglines.png) repeat;
    background-size: 55px 55px;
}

.loginBody .copyRight{
    width: 100%;
    display: block;
    position: absolute;
    left:0;
    bottom:24px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    color:#fff;
    line-height: 19px;
}
/*로그?�� : ?��*/




/*로딩 : ?��?��*/
.loadingBody{background: var(--main);}

.loadingBody > .logo{
    position: absolute;
    width:180px;
    display: block;
    top:40vh;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadingBody .bgBottom{
    width: 100%;
    display: block;
    position: absolute;
    left:0;
    bottom:0px;
    height: 140px;
    background: url(../images/bglines.png) repeat;
    background-size: 55px 55px;
}

.loadingBody .copyRight{
    width: 100%;
    display: block;
    position: absolute;
    left:0;
    bottom:24px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color:#fff;
    line-height: 19px;
}

.loadingLottie{
    display: block;
    width: 140px;
    position: absolute;
    left:calc(50% - 70px);
    top:47vh;
}
/*로딩 : ?��*/




/*?��?���??�� : ?��?��*/
.joinLogo{
    width:168px;
    display: block;
    margin:3px auto 0;
}

.joinImg2{
    width:310px;
    display: block;
    margin:26px auto 40px;

    border-radius: 20px;
}

.titleBar {
    display: block;
    width: 100%;
    height: 1px;
    background: #d0d0d0;
    /* margin: 40px auto; */
}

.dataLabel{
    display: block;
    width: 100%;
    margin: 32px 0 11px;
}

.dataLabel:first-child{margin: 0 0 11px}

.dataLabel:after{content: ""; display: block; clear: both;}

.dataLabel .sp{
    float: left;
    display: block;
    width: 10px;
    height: 10px;
    margin:3px 4px 0 0;
    background: url(../images/star001.png) center no-repeat;
    background-size: 10px 10px;
}

.dataLabel .label{
    display: block;
    float: left;
    /*height: 18px;*/
    line-height: 18px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    color:rgba(0,0,0,0.8);
    max-width: calc(100% - 14px);
}

.dataWrap{
    display: block;
    width: 100%;
    position: relative;
}

.dataWrap .searchIcon{
    position: absolute;
    width: 16px;
    height: 16px;
    top:12px;
    left: 13px;
    background: url(../images/search-14-333.png) center no-repeat;
    background-size: 14px 14px;
}
.inputAddress{
    padding:0 0 0 35px;
    background: #f3f3f3 url(../images/search-14-333.png) 13px no-repeat !important;
    background-size: 14px 14px !important;
}

.dataWrap:after{content: ""; display: block; clear: both;}

.dataWrap + .dataWrap{margin:12px 0 0;}

.dataWrap .buttonShowPolicy{
    position: absolute;
    top:-5px;
    right: 0;
    width: 30px;
    height: 30px;
    background: #fff url(../images/btn-prev-a12.png) 11px center no-repeat;
    background-size: 7px 13px;
    border-radius: 4px;
    opacity: 0.5;
    border:1px solid #d0d0d0;
}

.dataWrap.dataWrapPolicy{margin:28px 0 0 !important;}

.inputStyleWrap{
    display: block;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #DEDEDE;
    padding:0 17px;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
}

.inputStyleWrap:after{content: ""; display: block; clear: both;}

.inputStyleWrap > .inputTrans{
    float: left;
    display: block;
    width: calc(50% - 15px);
    border:none !important;
    outline:none !important;
    background: none !important;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
}

.inputStyleWrap > .cardUnderline{
    float: left;
    display: block;
    width: 26px;
    border:none !important;
    outline:none !important;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 88%/3ch 2px no-repeat;
    letter-spacing: 0.8ch;
    padding-left:1px;
}

.inputStyleWrap > .birthUnderline{
    float: left;
    display: block;
    width: calc(50% - 15px);
    border:none !important;
    outline:none !important;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 88%/9ch 2px no-repeat;
    letter-spacing: 0.5ch;
    padding-left:0px;
}

.inputStyleWrap > .birthUnderline2{
    float: left;
    display: block;
    width: 30px;
    border:none !important;
    outline:none !important;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 88%/1.4ch 2px no-repeat;
}

.inputTrans::placeholder{color:#bbb;}

.inputStyleWrap > .inputCenterTxt + .inputTrans{
    text-align: right;
}

.inputStyleWrap > .inputCenterTxt{
    display: block;
    width: 30px;
    text-align: center;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    float: left;
}

.inputStyleWrap > .inputTrans2{
    float: left;
    display: block;
    width: 30px;
    border:none !important;
    outline:none !important;
    background: none !important;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
}

.inputStyleWrap > .etc{
    float: left;
    display: block;
    width: calc(50% - 45px);
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    padding:0 0 0 2px;
}

.buttonWithInput{
    float: right;
    width: 112px;
}

.inputWithButton{
    float: left;
    width: calc(100% - 122px);
}

.warning,
.available{
    display: block;
    width: 100%;
    margin:12px 0 0;
    position: relative;
}

.warning:after{content: ""; display: block; clear: both;}
.available:after{content: ""; display: block; clear: both;}

.warning .txt,
.available .txt {
    display: block;
    float: right;
    width: calc(100% - 12px);
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
    color:#FF2828;
}

.warning:before{
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    left:0;
    top:6px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #FF2828;
}

.available:before{
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    left:0;
    top:6px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: green;
}


.warning2{
    display: block;
    width: 100%;
    margin:12px 0 0;
    position: relative;
}

.warning2:first-child{margin:0}

.warning2:after{content: ""; display: block; clear: both;}

.warning2 .txt{
    display: block;
    float: right;
    width: calc(100% - 12px);
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
    color:#999;
}

.warning2:before{
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    left:0;
    top:7px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #999;
}


.warning3{
    display: block;
    width: 100%;
    margin:12px 0 0;
    position: relative;
}

.warning3:first-child{margin:0}

.warning3:after{content: ""; display: block; clear: both;}

.warning3 .txt{
    display: block;
    float: right;
    width: calc(100% - 12px);
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
    color:#777;
}

.warning3:before{
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    left:0;
    top:7px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #777;
}


.warning + .dataWrap{margin:32px 0 0;}

.dataWrap > .timeLimit{
    position: absolute;
    display: block;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    height: 44px;
    line-height: 44px;
    right: 135px;
    top:0;
}

.bottomButtons{
    display: block;
    width: 100%;
    position: fixed;
    min-width: 280px;
    z-index: 100;
    left: 0;
    bottom: 0;
}

.bottomButtons:after{content: ""; display: block; clear: both;}

.bottomButtons .button1in2{
    width: 50%;
    float: left;
}
/*?��?���??�� : ?��*/



/*?��?���??�� - ?���??��?�� : ?��?��*/
.policyAgreeModalContent{
    height: calc(100vh - 200px) !important;
}

.buttonAgreeAll{
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin:0 0 20px;
    background: #fff;
    text-align: center;
    font-size: 15px;
    color: white !important;
    font-weight: 500;
    border-radius: 8px;
    background: var(--main);
}

.policyTitle{
    display: block;
    width: 100%;
    float: left;
    position: relative;
}

.policyTitle:after{content: ""; display: block; clear: both;}

.policyM2{margin:24px 0 0;}

.rowP1a{margin:0 0 20px;}

.lineP1{
    display: block;
    width: 100%;
    height: 1px;
    margin:10px 0;
    border-top:1px dashed rgba(0,0,0,0.1);
}

.policyChildList{
    display: none;
    width: 100%;
    padding:20px 24px;
    margin:20px 0;
    border:1px solid rgba(0,0,0,0.1);
    background: #fff;
}

.active + .policyChildList{display: block;}

.policyChildList .policyContent{background: #f4f4f4}

.pBipt .icon{margin:1px 4px 0 0}

.policyChildList .dataWrap .brisakInput + .brisakInput{margin:0 0 0 24px}

.policyChildList .dataWrap{margin:10px 0 0;}

.policyChildList .row + .row{margin:10px 0 0}

.policyTitle > .t1{
    display: block;
    float: left;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    padding:0 4px 0 0;
    color:var(--red);
}

.policyTitle > .t2{
    display: block;
    float: left;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
}

.policyTitle > .detail{
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top:-10px;
    right: 0;
    background: url(../images/btn-prev-a12.png) right no-repeat;
    background-size: 6px 10px;
}

.policyTitle > .detailOpen{
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top:-10px;
    right: 0;
    background: url(../images/btn-prev-a12.png) top no-repeat;
    background-size: 8px 15px;
    transform: rotate(90deg);
}

.policyTitle > .detailOpen.active{
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top:-10px;
    right: 0;
    background: url(../images/btn-prev-a12.png) bottom no-repeat;
    background-size: 8px 15px;
    transform: rotate(270deg);
}

.policyContent{
    display: none;
    width: 100%;
    text-align: left;
    margin:12px 0 40px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color:#666;

    padding:10px;
    /*border:1px solid #d0d0d0;*/
    background: #fff;
    border-radius: 8px;
}

.policyContent.active {
    display: block;
}
/*?��?���??�� - ?���??��?�� : ?��*/






/*메인 - ?��비스 ?��?�� : ?��?��*/
.serviceSubTitle{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color:#111;
    padding:0 0 14px;
    /*background: #fff;*/

}

.serviceImg1{
    display: block;
    width: 100%;
    /*max-width: 400px;*/
    margin:0 auto 40px;
    border-radius: 20px;
}

.priceWrap{
    display: block;
    width: 100%;
    min-height: 44px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #DEDEDE;
    text-align: left;
    color:#111;
    font-size: 15px;
    font-weight: 500;
    margin:12px 0 0;
    padding:9px 17px;
}

.priceWrap:after{content: ""; display: block; clear: both;}

.priceWrap .label{
    float: left;
    height: 24px;
    line-height: 24px;
    font-size: 15px;
    font-weight: 500;
    color:#111;
}

.priceWrap .price{
    float: right;
    text-align: right;
    height: 24px;
    line-height: 24px;
    font-size: 15px;
    font-weight: 500;
    color:#111;
}

.priceWrap .price > .t1{
    font-weight: 700;
    color:var(--main);
}

.priceWrap .price.original{
    color:#aaa;
    text-decoration: line-through;
}

.priceWrap .price.original > .t1{
    font-weight: 500;
    color:#aaa
}

.priceWrap + .warning{margin:12px 0 0}
.priceWrap + .dataWrap{margin:12px 0 0}
/*메인 - ?��비스 ?��?�� : ?��*/




/*배송?�� ?��?�� - �? 캐비?�� 배송?���? ?��?�� : ?��?��*/
.txtB1{
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    font-weight: 400;
    margin:0 0 47px;
    position: relative;
}

.txtB1a{text-align: center;}

.txtB1 > .updateDate{
    /*position: absolute;*/
    font-size: 13px;
    /*top:0;
    right: 0;*/
    line-height: 20px;
    font-weight: 500;
    color:#333;
    display: block;
    width: 100%;
}

.txtB1 > .t1{
    font-weight: 700;
    /*color:var(--red);*/
}

.lineB1{
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(0,0,0,0.2);
    margin:60px 0 0;
}

.txtB2{
    display: block;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
    margin:20px 0 ;
    position: relative; /* 2024.04.04 추�?? */
}

.wrapB1{
    display: block;
    width: 100%;
}

.wrapB1 > .dataWrap + .dataWrap{margin:20px 0 0}
/*배송?�� ?��?�� - �? 캐비?�� 배송?���? ?��?�� : ?��*/




/*배송?���?, 배송?��?�� - ?��?��*/
.modalNoHeader > .imgDelivery{
    display: block;
    width: 100%;
    border-radius: 10px;
    margin:20px 0 0;
}

.modalNoHeader > .reason{
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    color:#444;
    text-align: left;
    padding:16px;
    margin:15px 0 0;
    background: #f0f0f0;
    border-radius: 10px;
}
/*배송?���?, 배송?��?�� - ?��*/






/*?���?/배송?���? : ?��?��*/
.deliveryContents > .centerWrap {
    padding: 59px 20px 100px !important;
}

.passwordLabel{
    display: block;
    float: left;
    font-size: 11px;
    text-align: left;
    height: 26px;
    line-height: 26px;
    margin:13px 8px 0 0;
}

.passwordData{
    display: block;
    float: left;
    margin: 12px 0 0;
}

.passwordData:after{content: ""; display: block; clear: both;}

.passwordData > .data{
    display: block;
    float: left;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    text-align: center;
    line-height: 22px;
    background: #fff;
    border:1px solid #A5A5A5;
    font-size: 14px;
    font-weight: 800;
    color: #111;
}

.passwordData > .data + .data{margin:0 0 0 4px};

.titleWrap2{
    display: block;
    width: 100%;
}

.titleWrap2:after{content: ""; display: block; clear: both;}

.titleWrap2 > .title{
    display: block;
    float: left;
    height: 21px;
    line-height: 21px;
    text-align: left;
    color:#111;
    font-size: 18px;
    font-weight: 600;
}

.titleWrap2 > .free{
    display: block;
    float: left;
    padding:0 5px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color:#fff;
    font-size: 12px;
    font-weight: 700;
    background: var(--main);
    border-radius: 4px;
    margin:0 0 0 8px;
}

.titleWrap2 > .coupon{
    display: block;
    float: left;
    padding:0 5px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color:#fff;
    font-size: 12px;
    font-weight: 700;
    background: var(--green);
    border-radius: 4px;
    margin:0 0 0 8px;
}

.titleWrap2 > .payMore{
    display: block;
    float: left;
    /*width: 80px;*/
    padding:0 5px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color:#fff;
    font-size: 12px;
    font-weight: 700;
    background: var(--red);
    border-radius: 4px;
    margin:0 0 0 8px;
}

.deliveryContents .box1{
    display: block;
    width: 100%;
    padding: 12px;
    background: #fff;
    border-radius: 10px;
    border:1px solid #e4e4e4;
    box-shadow: 0 2px 6px #DEDEDE;
    margin:12px 0 0;
}




.deliveryContents .box1 > .noList{
    display: block;
    width: 100%;
    text-align: center;
    padding:6px 0 11px;
}

.deliveryContents .box1 > .noList > img{
    width: 64px;
    height: 64px;
    display: block;
    margin:0 auto 9px;
}

.deliveryContents .box1 > .noList > .txt{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color:#111;
    line-height: 20px;
}

.deliveryContents .box1 > .service .status{
    display: block;
    float: left;
    /*width: 80px;*/
    padding: 0 11px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color:#fff;
    font-size: 12px;
    font-weight: 700;
    background: #193a6e/*var(--main)*/;
    border-radius: 4px;
}

.deliveryContents .box1 > .service .row2{margin:7px 0 0}

.deliveryContents .box1 > .service .row2 .date{
    height: 22px;
    line-height: 22px;
    display: block;
    float: left;
    font-size: 13px;
    font-weight: 600;
}

.deliveryContents .box1 > .service .row2 .shipping{
    height: 22px;
    line-height: 22px;
    display: block;
    float: left;
    font-size: 13px;
    font-weight: 600;
    margin:0 0 0 7px;
}

.deliveryContents .box1 > .service .row2 .date2{
    /*height: 22px;*/
    line-height: 22px;
    display: block;
    float: left;
    font-size: 13px;
    font-weight: 600;
}

.deliveryContents .box1 > .service .row2 .date2 .t1{color:var(--red);}


.deliveryContents .box1 > .service .row2 .shipping.shipping3{color:var(--red);}

.deliveryContents .box1 > .service .row2 > .button{
    display: block;
    float: right;
    min-width: 80px;
    height: 22px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color:#111 !important;
    border:2px solid #111;
    border-radius: 11px;
    padding:0 11px;
}

.deliveryContents .box1 > .service .row2 > .button.button3{
    color:var(--red) !important;
    border:2px solid var(--red);
}

.deliveryContents .box1 > .service .serviceNow{
    display: block;
    width: 100%;
    border:1px solid #D9D9D9;
    border-radius: 10px;
    padding:13px 14px;
    margin:12px 0 0;
}

.deliveryContents .box1 > .service .serviceNow .serviceNowTitle{
    display: block;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
}

.deliveryContents .box1 > .service .serviceNow .serviceNowTitle .st1{color:#88B7FF}

.deliveryContents .box1 > .service .row4{margin:14px 0 0}

.deliveryContents .box1 > .service .row4 .serviceDate{
    display: block;
    float: left;
    height: 16px;
    line-height: 16px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
}

.deliveryContents .box1 > .service .row4 .servicePrice{
    display: block;
    float: left;
    height: 16px;
    line-height: 16px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}

.deliveryContents .box1 > .service .row4 .payDate{
    display: block;
    float: left;
    height: 16px;
    line-height: 16px;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin:0 0 0 28px;
    position: relative;
}

.deliveryContents .box1 > .service .row4 .payDate:before{
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 14px;
    top:1px;
    left:-14px;
    background: #E0E0E0;
}

.deliveryContents .box1 > .service .row4 .payStatus{
    display: block;
    float: left;
    height: 16px;
    line-height: 16px;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin:0 0 0 15px;
    color:var(--main);
}

.deliveryContents .box1 > .service .row4 .payStatus00{
    color:var(--main);
}

.deliveryContents .box2{
    display: block;
    width: 100%;
    padding: 18px 14px;
    background: #F3F3F3;
    border-radius: 10px;
    border:1px solid #e4e4e4;
    box-shadow: 0 2px 6px #DEDEDE;
    margin:12px 0 0;
    text-align: center;
}

.deliveryContents .box2 .boxTxt{
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
}

.serviceNow > .noList > img {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto 9px;
}

.serviceNow > .noList > .txt {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #111;
    line-height: 20px;
}

.tabs{
    display: block;
    width: 100%;
    margin:12px 0 0;
    border-bottom:1px solid rgba(0,0,0,0.2);
}

.tabs:after{content: ""; display: block; clear: both;}

.tabs > .tab{
    float: left;
    width: 50%;
    display: block;
    position: relative;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    font-weight: 500;
    color: #777;
    text-align: center;
}

.tabs > .tab.active{
    color:var(--main) !important;
    font-weight: 700;
}

.tabs > .tab.active:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom:-1px;
    left:0;
    background: var(--main);
}

.tabArea{
    display: none;
    width: 100%;
}

.tabArea.active{display: block;}

.deliveryContents .blueBox{
    display: block;
    width: 100%;
    padding: 17px 14px 13px;
    background: var(--main);
    border-radius: 10px;
    border:1px solid var(--borderDrakBlue);
    box-shadow: 0 2px 6px #DEDEDE;
    margin:12px 0 0;
    text-align: center;
}

.deliveryContents .blueBox .address{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color:#fff;
    line-height: 23px;
}

.deliveryContents .blueBox .buttonAddress{
    display: block;
    text-align: center;
    min-width: 80px;
    height: 28px;
    line-height: 24px;
    border:2px solid #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 14px;
    color:#fff !important;
    padding:0 11px;
    margin:9px auto 0;
}

.deliveryContents .histories{
    display: block;
    width: 100%;
    margin: 12px 0 0;
}

.deliveryContents .histories > .history{
    display: block;
    width: 100%;
    background: #fff;
    padding: 13px 15px 14px;
    border-radius: 10px;
    border:1px solid #E4E4E4;
    box-shadow: 0 2px 6px #DEDEDE;
}

.deliveryContents .histories > .history + .history{margin:12px 0 0}

.deliveryContents .histories > .history .date{
    display: block;
    float: left;
    height: 20px;
    line-height: 20px;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color:#777 !important;
}

.deliveryContents .histories > .history .payment{
    display: block;
    float: right;
    height: 20px;
    line-height: 20px;
    text-align: right;
    font-size: 13px;
    font-weight: 400;
    color:#777 !important;
}

.deliveryContents .histories > .history .status{
    display: block;
    float: left;
    height: 16px;
    line-height: 16px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color:#111 !important;
}

.deliveryContents .histories > .history .amount{
    display: block;
    float: right;
    height: 16px;
    line-height: 16px;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    color:#111 !important;
}

.deliveryContents .histories > .history .amount.money > .t1{color:var(--main) !important}

.deliveryContents .histories > .history .amount.free {color:var(--red) !important}

.deliveryContents .histories > .history .row + .row{margin:9px 0 0;}

.deliveryContents .histories > .noList{
    display: block;
    width: 100%;
    background: #fff;
    padding: 23px 15px 31px;
    border-radius: 10px;
    border:1px solid #E4E4E4;
    box-shadow: 0 2px 6px #DEDEDE;
    text-align: center;
}

.deliveryContents .histories > .noList > img{
    width: 48px;
    height: 48px;
}

.deliveryContents .histories > .noList > .txt{
    display: block;
    width: 100%;
    text-align: center;
    margin:12px 0 0;
    font-weight: 500;
    font-size: 13px;
    color:#111;
    line-height: 20px;
}
/*?���?/배송?���? : ?��*/











/*?���?/배송 ?���??���? : ?��?��*/
.contents2{}

.contents2 > .centerWrap{padding: 91px 20px 140px !important}

.boxD1{
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    border:1px solid #e4e4e4;
    padding:22px 10px 19px;
    text-align: center;
    margin:17px 0 12px;
    box-shadow: 0 2px 6px #dedede;
}

.boxD1 > .txt1{
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
}

.boxD1 > .txt2{
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
}

.boxD1 > .txt2 > span{color:var(--main);}

.boxD1 > .txt3{
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin:6px 0 0;

}

.boxD1 > .txt3 > span{
    color:var(--red);
    background: rgba(255,40,40,0.1);
    padding:5px;
    border-radius: 4px;
}

.boxD1 > .button1{
    width: 80px;
    height: 30px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color:#111 !important;
    border-radius: 15px;
    border:2px solid #111;
    margin:10px 0 0;
}


.boxD2{
    display: block;
    width: 100%;
    background: var(--main);
    border-radius: 10px;
    border:1px solid var(--borderDarkBlue);
    padding:19px 10px 14px;
    text-align: center;
    margin:24px 0 12px;
}

.boxD2 > .title{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    color:#fff;
}

.boxD2 > .notice{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color:rgba(255,255,255,0.7);
    margin:10px 0 0;
}

.boxD2 > .title + .row{
    margin:10px 0 0;
}

.boxD2 > .title + .row  .tag{
    display: block;
    float: left;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    text-align: center;
    padding:0 10px;
    font-size: 12px;
    font-weight: 700;
    background: #fff;
}

.boxD2 > .title + .row  .tag + .tag{margin:0 0 0 6px;}

.boxD2 .address{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color:#fff;
    margin:14px 0 0;
}

.boxD2 .phone{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin:11px 0 0;
    color: #fff;
}

.boxD2 .phone span{
    display: inline-block;
    line-height: 23px;
    /* background: url(../images/phone60w.png) left no-repeat; */
    background-size: 20px 19px;
    /* padding:0 0 0 25px; */
    font-size: 14px;
}


.boxD2 .password{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color:rgba(255,255,255,0.8);
    margin:11px 0 0;
}

.boxD2 > .buttonD21{
    width: 80px;
    height: 30px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color:#fff !important;
    border-radius: 15px;
    border:2px solid #fff;
    margin:14px 0 0;
}


.boxD3 {
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    border:1px solid #e4e4e4;
    padding:21px 19px 22px;
    text-align: center;
    margin:24px 0 0;
    box-shadow: 0 2px 6px #dedede;
}

.boxD3 .title{
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.boxD3 .txt1{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    padding:12px 0;
}

.boxD3 .buttons{
    display: block;
    width: 100%;
}

.boxD3 .buttons:after{content: ""; display: block; clear: both;}

.boxD3 .buttons > .buttonCategory{
    display: block;
    width: calc((100% - 10px) / 3);
    margin:10px 0 0 5px;
    float: left;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color:#3B3B3B !important;
    background: #F3F3F3;
}

.boxD3 .buttons > .buttonCategory:nth-child(3n - 2){
    margin:10px 0 0 0;
}

.boxD3 .buttons > .buttonCategory.active{
    background: var(--darkBlue);
    color:#fff !important;
    font-weight: 700;
}

.boxD4 {
    display: block;
    width: 100%;
    background: #F3F3F3;
    border-radius: 10px;
    box-shadow: 0 2px 6px #DEDEDE;
    padding:21px 15px;
    margin:24px 0 0;
}

.boxD4 > .title{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.payBox{
    display: block;
    width: 100%;
    border-radius: 10px;
    background: #fff;
    border:1px solid #e4e4e4;
    margin:9px 0 0;
    box-shadow: 0 2px 6px #DEDEDE;
    padding:14px 22px;
}

.payBox .date{
    float: left;
    height: 22px;
    line-height: 22px;
    font-weight: 700;
    font-size: 13px;
    color:var(--red);
    text-align: left;
}

.payBox .buttonD4Default {
    display: block;
    font-weight: 700;
    float: left;
    border:2px solid #111;
    border-radius: 11px;
    height: 22px;
    line-height: 18px;
    text-align: center;
    width: 50px;
    color: #111 !important;
    font-size: 12px;
    margin-right: 5px;
}

.payBox .buttonD4Edit{
    display: block;
    font-weight: 700;
    float: right;
    border:2px solid #111;
    border-radius: 11px;
    height: 22px;
    line-height: 18px;
    text-align: center;
    width: 50px;
    color: #111 !important;
    font-size: 12px;
}

.payBox .changeDefaultBtn {
    width: 150px;
    margin-left: 5px;
    display: inline-block;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.payBox .floatRight .buttonD4Edit{
    float: left;
}

.payBox .buttonD4Delete{
    display: block;
    font-weight: 700;
    float: right;
    border:2px solid #111;
    border-radius: 11px;
    height: 22px;
    line-height: 18px;
    text-align: center;
    width: 50px;
    color: #111 !important;
    font-size: 12px;
    margin:0 0 0 5px;

}


.boxD4 .row + .row{
    margin: 12px 0 0;
}

.payBox .label{
    display: block;
    float: left;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    font-weight: 600;
}

.payBox .number{
    display: block;
    float: left;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    font-weight: 600;
    margin:0 0 0 5px;
}

.payBox .payment{
    float: left;
    height: 22px;
    line-height: 22px;
    font-weight: 700;
    font-size: 13px;
    color:#777;
    text-align: left;
}

.payBox .payment.default{color:var(--red)}

.payBox .row:first-child {
    margin-bottom: 7px;
}

.warnings{
    display: block;
    width: 100%;
    margin:24px 0 0;
}
/*?���?/배송 ?���??���? : ?��*/




/*?��?��?��?�� : ?��?��*/
.buttonCancelPayment{
    display: block;
    float: right;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    color:var(--main) !important;
    font-weight: 500;
    margin: 12px 0 0;
    text-decoration:underline;
}
.viewMore{
    display: block;
    float: right;
    height: 21px;
    line-height: 22px;
    text-align: left;
    color:#111 !important;
    font-size: 12px;
    font-weight: 500;
    padding:0 10px 0 0;
    background: url(../images/btn-prev-a12.png) right no-repeat;
    background-size: 4px 7px;
}

.titleWrap2a{margin:28px 0 0}

.deliveryContents .box1 > .service .serviceNow .button{
    display: block;
    /* float: right; */
    min-width: 80px;
    height: 30px;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color:#111 !important;
    border:2px solid #111;
    border-radius: 11px;
    padding:0 11px;
    position: absolute;
    right: 40px;
    margin-top:-5px;
}

.serviceNowTitle.type2{
    float: left;
    width: calc(100% - 82px);
}

.deliveryContents .box1 > .service .status.silver{
    background: #777;
}

.buttonChangePayment{
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    background: var(--main);
    text-align: center;
    font-size: 14px;
    color:#fff !important;
    font-weight: 600;
    margin:12px 0 0;
}

.service > .row5{margin:12px 0 0;}

.service > .row5 > .payment{
    float: left;
    line-height: 21px;
    font-size: 13px;
    color:#111;
    font-weight: 600;
}

.service > .row6{
    display: block;
    line-height: 21px;
    font-size: 13px;
    color:#111;
    font-weight: 600;
    margin:5px 0 0;
}

.nextPayment{
    float: left;
    height: 21px;
    line-height: 21px;
    font-size: 12px;
    font-weight: 400;
}

.deliveryContents.type2 > .centerWrap{padding:100px 20px 100px !important;}

.box1 + .titleWrap2{margin:34px 0 0;}

.otherServices{
    display: block;
    width: 100%;
    margin:12px 0 0;
}

.otherService{
    display: block;
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 10px;
    border:1px solid #dedede;
    padding:15px 20px;
    cursor: pointer;
    box-shadow: 0 2px 6px #DEDEDE;
}

.otherService .servicePrice{
    margin:7px 0 0;
    font-size:16px;
    font-weight: 600;
}

.otherService .icon{
    position: absolute;
    display: block;
    top:15px;
    right: 15px;
    width: 20px;
    height: 20px;
    background: url(../images/check-004.png) center no-repeat;
    background-size: 20px 20px;
}

.otherService input:checked + .icon{
    background: url(../images/check-003.png) center no-repeat;
    background-size: 20px 20px;
}

.otherService input{
    position: absolute;
    top:0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}


.otherService .title{
    display: block;
    width: calc(100% - 24px);
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    text-align: left;
}

.otherService input:checked + .icon + .title{font-weight: 700;}

.otherService .content{
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #777;
    margin:3px 0 0;
}

.otherService .priceRow{
    display: block;
    width: 100%;
    margin:11px 0 0;
}

.otherService .priceRow:after{content: ""; display: block; clear: both;}

.otherService .priceRow .period{
    display: block;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color:#111;
    float: left;
}

.otherService .priceRow .t1{float: left;}

.otherService .priceRow .t1:after{content: ""; display: block; clear: both;}

.otherService .priceRow .originalPrice{
    display: block;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color:var(--red);
    text-decoration: line-through;
    float: left;
    margin:0 0 0 6px;
}

.otherService .priceRow .arrow{
    display: block;
    height: 24px;
    line-height: 24px;
    width:30px;
    float: left;
    background: url(../images/arrowRIght-36x32-111.png) center no-repeat;
    background-size: 18px 16px;
    opacity: 0.7;
}

.otherService .priceRow .discount{
    display: block;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    color:var(--red);
    float: left;
}

.otherService .priceRow .price{
    display: block;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color:#111;
    float: left;
    margin:0 0 0 6px;
}

.otherService + .otherService{margin:12px 0 0;}


.paymentHistories{
    display: block;
    width: 100%;
    margin:12px 0 0;
}

.paymentHistory{
    display: block;
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 10px;
    border:1px solid #dedede;
    padding:15px 20px;
    cursor: pointer;
    box-shadow: 0 2px 6px #DEDEDE;
}

.paymentHistory + .paymentHistory{margin:12px 0 0;}

.paymentHistory .row1{position: relative;}

.paymentHistory .row1 .date{
    display: block;
    float: left;
    font-size: 13px;
    font-weight: 600;
    height: 24px;
    line-height: 24px;
}

.paymentHistory .row1 .mark{
    display: block;
    float: left;
    height:20px;
    line-height: 20px;
    width:75px;
    border-radius: 4px;
    background: #daf3ff;
    text-align: center;
    font-size:11px;
    font-weight: 500;
    color:#111;
    margin:2px 0 0 5px;
}

.paymentHistory .row1 .mark.mark1{
    background: var(--main);
    color:white;
}

.paymentHistory .row1 .mark.mark2{
    background: var(--green);
    color:white;
}

.paymentHistory .row1 .mark.mark3{
    background: var(--red);
    color:white;
}

.paymentHistory .row1 .mark.mark4{
    background: #193a6e;
    color:white;
}

.paymentHistory .row1 .price1{
    display: block;
    height: 24px;
    line-height: 24px;
    float: right;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    color:#999;
}

.paymentHistory .row1 .price1.lineTr{text-decoration: line-through;}

.paymentHistory .row1 .price2{
    display: block;
    position: absolute;
    top:24px;
    right: 0;
    height: 24px;
    line-height: 24px;
    float: right;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    color:var(--red);
}

.paymentHistory .row1 .price2 .t1{
    color:var(--red);
    font-weight: 700;
}


.paymentHistory .title{
    display: block;
    width: 100%;
    margin:20px 0 0;
    font-weight: 700;
    font-size: 16px;

}

.paymentHistory .period{
    display: block;
    width: 100%;
    margin:5px 0 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color:#777;

}

.paymentHistory .payment{
    display: block;
    width: 100%;
    margin:10px 0 0;
}

.paymentHistory .payment .t1{
    display: block;
    float: left;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size:11px;
    font-weight: 400;
    color:#777;
}

.paymentHistory .payment .t2{
    display: block;
    float: right;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size:14px;
    font-weight: 500;
    color:#111;
}

.txtK1{
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    color:#444;
    font-weight: 500;
    margin: 7px 0 0;
}
/*?��?��?��?�� : ?��*/






/*?���?/배송 ?��?�� : ?��?��*/
.deliveryOne{
    display: block;
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 10px;
    border:1px solid #dedede;
    padding:15px 20px;
    cursor: pointer;
    box-shadow: 0 2px 6px #DEDEDE;
}

.deliveryOne + .deliveryOne{margin:12px 0 0;}

.deliveryOne .type{
    display: block;
    width: 100%;
}

.deliveryOne .type:after{content: ""; display: block; clear: both;}




.deliveryOne .typeA1{
    display: block;
    float: left;
    height: 20px;
    line-height: 20px;
    width: 45px;
    border-radius: 9px;
    background: #ff2828;
    color:#fff;
    font-size:11px;
    font-weight: 500;
    text-align: center;
}

.deliveryOne .typeA2{
    display: block;
    float: left;
    height: 20px;
    line-height: 20px;
    width: 45px;
    border-radius: 9px;
    background: #ff2828;
    color:#fff;
    font-size:11px;
    font-weight: 500;
    text-align: center;
}

.deliveryOne .typeB1{
    display: block;
    float: left;
    height: 20px;
    line-height: 18px;
    width: 45px;
    border-radius: 9px;
    background: #fff;
    color:#ff2828;
    font-size:11px;
    font-weight: 500;
    text-align: center;
    margin:0 0 0 5px;
    border:1px solid #ff2828;
}

.deliveryOne .typeB2{
    display: block;
    float: left;
    height: 20px;
    line-height: 20px;
    width: 45px;
    border-radius: 9px;
    background: #fff;
    color:#ff2828;
    font-size:11px;
    font-weight: 500;
    text-align: center;
    margin:0 0 0 5px;
    border:1px solid #ff2828;
}


.deliveryOne .titleRow{
    display: block;
    width: 100%;
    margin:10px 0 0;
}

.deliveryOne .titleRow:after{content: ""; display: block; clear: both;}


.deliveryOne .titleRow .left{
    display: block;
    float: left;
    height: 24px;
    line-height: 24px;
    font-size:15px;
    font-weight: 500;
    color:#111;
}

.deliveryOne .titleRow .right{
    display: block;
    float: right;
    height: 24px;
    line-height: 24px;
    font-size:15px;
    font-weight: 500;
    color:#111;    
}

.deliveryOne .rows{
    display: block;
    width: 100%;
    margin:14px 0 0;
}


.deliveryOne .row + .row{margin:4px 0 0;}

.deliveryOne .row .left{
    display: block;
    float: left;
    height: 24px;
    line-height: 24px;
    font-size:13px;
    font-weight: 500;
    color:#111;   
}

.deliveryOne .row .right{
    display: block;
    float: right;
    height: 24px;
    line-height: 24px;
    font-size:12px;
    font-weight: 400;
    color:#777;   
}


.warningX{
    display: block;
    width: 100%;
    padding: 18px 14px;
    background: #F3F3F3;
    border-radius: 10px;
    border:1px solid #e4e4e4;
    box-shadow: 0 2px 6px #DEDEDE;
    margin:12px 0 0;
    text-align: center;
    font-size:13px;
    line-height: 20px;
    font-weight: 500;
    color:#111;
}

.warningX:first-child{margin:0}

.warningX + .boxD2{margin:12px 0;}


.iineTs1{
    display: block;
    width: 100%;
    height: 1px;
    background: #d0d0d0;
    margin:30px 0;
}
/*?���?/배송 ?��?�� : ?��*/



/*?���?/배송 주소 ?���? : ?��?��*/
.yourLocation{
    height: 46px;
    line-height: 46px;
    border-radius: 5px;
    margin:14px 0;
}
/*?���?/배송 주소 ?���? : ?��*/




/*?��거�?? ?��?��/배송�? ?��?�� : ?��?��*/
.addressOne{
    display: block;
    width: 100%;
}

.addressOne + .addressOne{margin:12px 0 0;}

.addressOne > .row{
    display: block;
}

.addressOne > .row .tagA{
    float: left;
    display: block;
    height: 24px;
    line-height: 25px;
    font-size: 11px;
    color:#111;
    padding:0 11px;
    border-radius: 4px;
    background: #f0f0f0;
}

.addressOne > .row .tagA + .tagA{margin:0 0 0 4px;}

.addressOne > .row .tagA.tagA1{
    background: var(--main);
    color:#fff;
    font-weight: 600;
}

.addressOne > .row .tagA.tagA2{
    background: var(--red);
    color:#fff;
    font-weight: 600;
}

.addressOne {
    display: block;
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #dedede;
    padding: 15px 20px;
    cursor: pointer;
    box-shadow: 0 2px 6px #DEDEDE;
}

.addressOne  .addressCol{
    display: block;
    cursor: pointer;
    /*float: left;*/
    width: 100% /*calc(100% - 45px)*/;
}

.addressOne .row + .row2{margin:14px 0 0;}

.addressOne  .addressCol input{display: none;}

.addressOne  .addressCol  .icon{
    float: left;
    width: 20px;
    height: 20px;
    background: url(../images/check-004.png) center no-repeat;
    background-size: 20px 20px;
}

.addressOne  .addressCol input:checked + .icon{
    background: url(../images/check-003.png) center no-repeat;
    background-size: 20px 20px;
}

.addressOne  .addressCol  .txtWrap{
    float: right;
    width: calc(100% - 25px);
}

.addressOne  .addressCol  .txtWrap .addressTxt{
    display: block;
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color:#111;
}

.addressOne  .addressCol .txtWrap .phoneTxt{
    display: block;
    width: 100%;
    line-height: 19px;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    color:#777;
    margin:7px 0 0;
    background: url(../images/phone60.png) left no-repeat;
    background-size: 20px 19px;
    padding:0 0 0 25px;
}

.addressOne  .addressCol  .txtWrap:after{content: ""; display: block; clear: both;}

.addressOne .floatRight{
    width: 45px;
}

.addressOne .buttonDefault {
    display: block;
    width: 60px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    font-size: 13px;
    color:#111;
    font-weight: 500;
    background: #fff;
    border:1px solid #e0e0e0;
    float: left;
    margin-right: 8px;
}

.addressOne .buttonEdit{
    display: block;
    width: 60px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    font-size: 13px;
    color:#111;
    font-weight: 500;
    background: #fff;
    border:1px solid #e0e0e0;
    float: left;
}

.addressOne .buttonDelete{
    display: block;
    width: 60px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    font-size: 13px;
    color:#111;
    font-weight: 500;
    margin:0 0 0 8px;
    background: #fff;
    float: left;
    border:1px solid #e0e0e0;
}

.addressOne .changeDefaultBtn {
    width: 140px;
    display: inline-block;
    margin-left: 8px;
    font-size: 13px;
    text-decoration: underline;
    padding-top: 4px;
}

.addressOne .row3{
    margin:20px 0 0;
    padding:0 0 0 25px;
}
/*?��거�?? ?��?��/배송�? ?��?�� : */



/*?�� ?��?�� : ?��?��*/
.appSettingLogo{
    display: block;
    margin:0 auto 30px;
    width:120px;
}

.appSettingBox{
    display: block;
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #dedede;
    padding: 20px 20px;
    cursor: pointer;
    box-shadow: 0 2px 6px #DEDEDE;
}

.appSettingBox + .appSettingBox{margin: 12px 0 0;}

.appSettingBox .titleRow{
    padding:0 0 10px;
    border-bottom:1px solid #d0d0d0;
    margin:0 0 12px;
}

.appTitle{
    display: block;
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color:#111;
}

.appSettingRow .appSettingLeft{
    display: block;
    float: left;
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
}

.appSettingRow .appSettingRight{
    display: block;
    float: right;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    font-size: 14px;
    color:#777;
    text-align: right;
}

.appSettingBox .appTxt1{
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    color:#777;
    margin:2px 0 0;
}


.toggleButton{
    display: block;
    width: 60px !important;
    position: relative;
    cursor: pointer;
}

.toggleButton input{
    position: absolute;
    top:0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.toggleButton .toggleWrap{
    display: block;
    width: 100%;
    height: 30px;
    border-radius: 30px;
    position: relative;
    background: #e0e0e0;
}

.toggleButton .toggleWrap .buttonBody{
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    top:3px;
    left:3px;
    background: #fff;
}

.toggleButton input:checked + .toggleWrap{
    background: var(--main);
}

.toggleButton input:checked + .toggleWrap .buttonBody{
    top:3px;
    left:auto;
    right: 3px;
}
/*?�� ?��?�� : ?��*/




/*공�???��?�� : ?��?��*/
.noticeWrap{
    display: block;
    width: 100%;
    /*border-top:1px solid #b0b0b0;*/
}

.noticeOne + .noticeOne{margin:12px 0 0;}

.noticeOne{
    position: relative;
    display: block;
    width: 100%;
    padding: 18px 28px 18px 16px;
    background: #fff;
    /*border-bottom:1px solid #d0d0d0;*/
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    box-shadow: 0 2px 6px #dedede;
}

.noticeOne:after{
    content: "";
    position: absolute;
    background: url(../images/sv-icon1.png) center no-repeat;
    background-size: 3px 13px;
    width: 3px;
    height: 13px;
    top: 23px;
    right: 16px;
    /*width: 6px;
    height: 10px;
    right: 16px;
    top:23px;
    position: absolute;
    transform: rotate(180deg);
    background: url(../images/btn-prev-a1.png) center no-repeat;
    background-size: 6px 10px;
    opacity: 0.6;*/
}


.noticeOne .noticeTitle{
    display: block;
    font-size: 15px;
    line-height: 22px;
    color:#111 !important;
    font-weight: 600;
    text-align: left;
}

.noticeOne .noticeDate{
    display: block;
    font-size: 13px;
    line-height: 20px;
    color:#777 !important;
    font-weight: 400;
    margin:5px 0 0;
    text-align: left;
}

.noticeDetailTop{
/*    position: relative;
    display: block;
    width: 100%;
    padding: 18px 16px;
    background: #fff;
    border-bottom:1px solid #d0d0d0;*/
    position: relative;
    display: block;
    width: 100%;
    padding: 18px 28px 18px 16px;
    background: #fff;
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    box-shadow: 0 2px 6px #dedede;
}

.noticeDetailTop .noticeTitle{
    display: block;
    font-size: 15px;
    line-height: 22px;
    color:#111 !important;
    font-weight: 600;
    text-align: left;
}

.noticeDetailTop .noticeDate{
    display: block;
    font-size: 13px;
    line-height: 20px;
    color:#777 !important;
    font-weight: 400;
    margin:5px 0 0;
    text-align: left;
}

.noticeDetailContent{
    position: relative;
    display: block;
    width: 100%;
    padding: 18px 16px;
    background: #fff;
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    box-shadow: 0 2px 6px #dedede;
    margin:12px 0 0;
    font-size:13px;
    line-height: 20px;
    font-weight: 400;
    color:#222;
    text-align: left;
}

.noticeDetailContent img{
    width: 100%;
    margin:5px 0;
}

.noticeDetailContent img:first-child{
    margin:0 0 5px;
}
/*공�???��?�� : ?��*/



/*마이?��?���? : ?��?��*/
.buttonMypageEdit {
    display: block;
    float: right;
    min-width: 50px;
    height: 22px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #111 !important;
    border: 2px solid #111;
    border-radius: 11px;
    padding: 0 11px;
    background: #fff;
}

.mypageBox1{
    position: relative;
    display: block;
    width: 100%;
    padding: 18px 16px;
    background: #fff;
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    box-shadow: 0 2px 6px #dedede;
    text-align: left;
    margin:12px 0 0;
}

.mypageBox1 .row .label{
    float: left;
    width: 90px;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    color:#111;
}

.mypageBox1 .row .data{
    float: right;
    width: calc(100% - 90px);
    line-height: 30px;
    text-align: right;
    font-size: 13px;
    font-weight: 400;
    color:#555;
}

.mypageBox1 .row .data:after{content: ""; display: block; clear: both;}

.mypageBox1 .row .data .snsIcon{
    float: right;
    display: block;
    padding:0 10px;
    text-align: center;
    font-size: 11px;
    color:#fff !important;
    font-weight: 400;
    border-radius: 3px;
    background: #111;
    height: 20px;
    line-height: 20px;
    margin:5px 0 0 3px;
}

.myPageEtcs{
    display: block;
    width: 100%;
    margin: 12px 0 0;
}

.myPageEtc{
    position: relative;
    display: block;
    width: 100%;
    padding: 18px  33px 18px 16px;
    background: #fff;
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    box-shadow: 0 2px 6px #dedede;
    text-align: left;
    margin:12px 0 0;
    font-size: 13px;
    font-weight: 600;
    color:#111 !important;
}

.myPageEtc:after{
    content: "";
    position: absolute;
    background: url(../images/sv-icon1.png) center no-repeat;
    background-size: 3px 13px;
    width: 3px;
    height: 13px;
    top: 19px;
    right: 16px;
    /*width: 6px;
    height: 10px;
    right: 16px;
    top:23px;
    position: absolute;
    transform: rotate(180deg);
    background: url(../images/btn-prev-a1.png) center no-repeat;
    background-size: 6px 10px;
    opacity: 0.6;*/
}

.myPageEtc:after{content: ""; display: block; clear: both;}

.myPageEtc .label{
    float: left;
    line-height: 15px;
    width: 100px;
}

.myPageEtc .friendEvent{
    width: calc(100% - 100px);
    float: right;
    line-height: 15px;
    text-align: right;
    font-weight: 500;
    color:var(--red) !important;
}

.myPageEtc.myPageEtc2 .label{
    width: 55px;
}
.myPageEtc.myPageEtc2 .friendEvent{
    width: calc(100% - 56px);
}

.mpTxt{
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color:var(--main);
    line-height: 24px;
    text-align: center;
    padding:40px 0 20px ;
}

.gotoLogin{
    display: block;
    width: 100%;
    margin:20px 0 20px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color:#fff !important;
    font-weight: 600;
    font-size: 17px;
    border-radius: 5px;
    background: var(--main);
}
/*마이?��?���? : ?��*/



/*친구 초�?? : ?��?��*/
.couponImgWrap{
    display: block;
    width: 100%;
    padding:40px 0 ;
    /* background: var(--main); */
    /*background: url(../images/bg1.jpg) center no-repeat;
    background-size: cover;*/
    text-align: center;
    border-radius: 15px;
}

.couponImgWrap > img{
    width:200px;
    filter : drop-shadow(0 3px 9px rgba(0,0,0,0.2));
}

.userPoint{
    display: block;
    width: 100%;
    margin:40px 0 0;
    border-top:1px solid #555;
    border-bottom:1px solid #d0d0d0;
}

.userPoint:after{content: ""; display: block; clear:both;}

.userPoint .t1{
    float: left;
    height: 60px;
    line-height: 60px;
    color:#111 !important;
    text-align: left;
    font-size:14px;
    font-weight: 500;
}

.userPoint .t2{
    float: right;
    height: 60px;
    line-height: 60px;
    color:#111 !important;
    text-align: left;
    font-size:16px;
    font-weight: 700;
    background: url(../images/btn-prev-a12.png) right no-repeat;
    background-size: 6px 10px;
    padding: 0 14px 0 0;
}


.copyCode{
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 6px;
    text-align: center;
    color:#fff !important;
    font-size: 16px;
    font-weight: 500;
    background: var(--main);
    margin:30px 0 0;
}

.shareLink{
    display: block;
    width: 100%;
    height: 60px;
    line-height: 58px;
    border-radius: 6px;
    text-align: center;
    color:#111 !important;
    border:1px solid #d0d0d0;
    font-size: 16px;
    font-weight: 500;
    background: #fff;
    margin:12px 0 50px;
}

.warningTitle{
    display: block;
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color:#111;
}
/*친구 초�?? : ?��*/



/*친구 추�?? : ?��?��*/
.pointCol{
    float: left;
    display: block;
    width: calc(50% - 6px);
    background: #fff;
    border-radius: 10px;
    padding:14px 10px;
    text-align: center;

}

.pointCol + .pointCol{float: right;}

.pointCol .txt1{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    color:#777;
    font-weight: 400;
}

.pointCol .txt2{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    color:#111;
    font-weight: 500;
    margin:5px 0 0;
}

.pointTable{
    width: 100%;
    margin:24px 0 40px;
}

.pointTable th{
    background: #444;
    color:#fff;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding:12px 1px;
}

.pointTable td{
    background : #fff;
    color:#111;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding:12px 3px;
}

.pointTable tr{
    border-bottom:1px solid #e0e0e0;
}
/*친구 추�?? : ?��*/




/*?��?���? : ?��?��*/
.introContents .centerWrap{
    padding:16px 20px 50px !important;
    background: #fff;
    min-height: 100vh;
}

.pageIntro{
    display: block;
    position: fixed;
    bottom:30px !important;
    left:calc(50% - 70px) !important;
    width:140px !important;
}

.introContents .swiper-pagination-bullet{
    width: calc((100% - 24px) / 3);
    height: 4px;
    border-radius: 0px;
}

.introTxt1{
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color:#444;
    padding:50px 0 40px;
}

.introTxt2{
    display: block;
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color:#444;
    position: fixed;
    left:0;
    text-align: center;
    width: 100%;
    bottom:60px;
}

.skipIntro{
    float: right;
    height: 30px;
    line-height: 30px;
    text-align: right;
    color:#111 !important;
    font-size: 14px;
    font-weight: 500;
    background: url(../images/btn-prev-a12.png) right no-repeat;
    background-size: 6px 10px;
    padding:0 12px 0 0;
}

.introSwiper{
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.introImgs{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap:10px;
}

.introImg{
    display: block;
    width: calc(50% - 5px);
    border-radius: 12px;
}

.introImg.active1,
.introImg.active2,
.introImg.active3 {
    border: 3px solid orangered;
}
/*?��?���? : ?��*/

/*컨텐�? : ?��?��*/
.cbTxt0{
    display: block;
    width: 100%;
    text-align: center;
    padding:20px;
    border-radius: 10px;
    margin:0 0 20px;
    font-size:15px;
    font-weight: 500;
    color:var(--main);
    line-height: 23px;
    background: rgba(0,100,255,0.1);
}

.introTxt {
    text-align: start !important;
}

.cBox{
    display: block;
    width: 100%;
    border-radius: 10px;
    background: #fff;
    padding:0 24px 1px;
}

.cBox .cbTxt1{
    display: block;
    width: 100%;
    height:50px;
    line-height: 50px;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color:#222;
    border-bottom:1px solid rgba(0,0,0,0.1);

}

.cBox .cbTxt2{
    display: block;
    width: 100%;
    text-align: left;
    margin:20px 0 0;
    font-size: 16px;
    line-height: 22px;
    color:#222;
    font-weight: 600;
}

.cBox .cbTxt3{
    display: block;
    width: 100%;
    text-align: left;
    margin:8px 0 0;
    font-size: 14px;
    line-height: 22px;
    color:#555;
    font-weight: 500;
}

.cBox .cbTxt1 + .cbTxt3{margin:20px 0 0;}

.cBox img{
    display: block;
    width: 100%;
    /* max-width: 240px; */
    margin:10px auto;
    border-radius: 10px;
}
.cBox .cbButton{
    display: block;
    width: 100%;
    border-radius: 5px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 14px;
    color:#fff !important;
    font-weight: 600;
    background: #222;
    margin:0 auto;
    max-width: 240px;
}
.cBox + .cBox{margin:20px 0 0}
.cBox{}
/*컨텐�? : ?��*/



























.inputUnderline {
    background: repeating-linear-gradient(90deg, dimgrey 0ch, dimgrey 1.0ch, transparent 0, transparent 1.5ch) 1.8ch 88%/6ch 2px no-repeat #fff;
    letter-spacing: 0.4ch;
    
}

.deliveryB {
    display: block;
    text-align: center;
    min-width: 80px;
    height: 28px;
    line-height: 24px;
    border: 2px solid var(--main);
    font-size: 12px;
    font-weight: 700;
    border-radius: 14px;
    color: #fff !important;
    padding: 0 11px;
    background: var(--main);
    float:right;
}

.deliveryButton {
    margin-left: 4px;
}

/*#######################################*/
@media screen and (max-width: 500px) {


} /*반응?�� 500px ?��*/

/*#######################################*/
@media screen and (max-width: 488px) {



} /*반응?�� 488px ?��*/



/*#######################################*/
@media screen and (max-width: 450px) {

    /*?��?��?��?�� : ?��?��*/
    /* .centerWrap{padding:0 15px;} */

    .centerWrap img {
        width: 100%;
    }

    .contents > .join{padding: 120px 15px 100px}

    .deliveryContents > .centerWrap {
        padding: 59px 15px 100px !important;
    }

    .mainContents > .centerWrap {
        padding: 50px 15px 100px !important;
    }

    .deliveryContents.type2 > .centerWrap {
        padding: 100px 15px 100px !important;
    }

    .contents2 > .centerWrap {
        padding: 91px 15px 100px !important;
    }
    /*?��?��?��?�� : ?��*/



    .login{
        padding:100px 0 0;
        margin:0 auto;
    }

    .login > .txt1{
        font-size: 20px;
        line-height: 23px;
        margin:30px 0 34px;
    }

    .login > .line1{margin:40px 0;}


    /*?��비스 �?�? : ?��?��*/
    /*.deliveryContents .box1 > .service .row4{margin:5px 0 0;}*/

    .deliveryContents .box1 > .service .row4 .payDate{
        width: 100%;
        margin:5px 0 0;
    }

    .deliveryContents .box1 > .service .row4 .payDate:before{
        display: none;
    }

    /*?��비스 �?�? : ?��*/

    .inputNormal1in4{width:70px; float:left;}
    .inputNormal{padding: 0 10px;}
    .inputUnderline {
        background: repeating-linear-gradient(90deg, dimgrey 0ch, dimgrey 1.0ch, transparent 0, transparent 1.5ch) 1.2ch 88%/6ch 2px no-repeat #fff;
        letter-spacing: 0.4ch;
        
    }




} /*반응?�� 450px ?��*/




/*#######################################*/
@media screen and (max-width: 408px) {



} /*반응?�� 408px ?��*/


@media screen and (max-width: 351px) {
    header h1{
        font-size: 18px;
    }

    .hide340{display: none;}
    .gotoTop{
        right:11px;
        bottom:80px;
        width: 40px;
        height: 40px;
        background: url(../images/go_top.png) center no-repeat;
        background-size: 40px 40px;
    }


    .gotoTop2{
        bottom : 20px;
    }

    /*?�� : ?��?��*/
    .homeHeader .selfStorage{
        padding: 0 5px;
        margin: 16px 3px 0 0;
    }

    .mainSwiper .txtWrap > .txt1{
        font-size:20px;
        line-height:30px;
    }

    .mainSwiper .txtWrap > .txt2{
        font-size:15px;
        line-height: 23px;
    }

    .mainSwiper .txtWrap > .txt3{
        font-size: 13px;
        line-height: 21px;
    }

    .mainTopMenus .mainTopMenu{font-size: 13px;}

    .mainTxt1{
        font-size:13px;
        padding:65px 0 22px;
    }

    .services .service .col1{width:80px;}

    .services .service .col1 .imgItem{
        width:88px;
        height: 88px;
        border-radius: 8px;
    }

    .services .service .col2 {
        float: right;
        width: calc(100% - 102px);
        position: relative;
    }

    .services .service .col2 > .txt1{font-size: 15px;}

    .services .service .col2 > .txt2{
        font-size: 13px;
        line-heiget:18px;
    }

    .services .service .col2 .discount{font-size:14px;}

    .services .service .col2 .price{font-size:14px;}
    /*?�� : ?��*/


    /*?���?/배송?���? : ?��?��*/
    .titleWrap2 > .title{font-size: 16px;}

    .titleWrap2 > .free{
        padding:0 7px;
        margin: 0 0 0 5px;
    }

    .titleWrap2 > .payMore{
        width: auto;
        padding:0 7px;
        margin:0 0 0 4px;
    }

    .passwordData > .data{
        width: 20px;
        height: 20px;
        line-height: 18px;
        margin: 2px 0 0;
    }

    .tabs > .tab{font-size: 14px;}

    .boxD2 > .title{font-size: 16px;}

    .passwordLabel{margin:13px 5px 0 0;}

    .passwordData > .data + .data{margin:2px 0 0 2px;}

    .deliveryContents .box1 > .service .row2 .date{font-size: 12px;}

    .deliveryContents .box1 > .service .row2 .shipping{
        font-size: 12px;
        margin:0 0 0 5px;
    }

    .deliveryContents .box1 > .service .row2 > .button{
        min-width: 40px;
        padding:0 10px;
    }

    .deliveryContents .box1 > .service .serviceNow .serviceNowTitle{
        font-size: 15px;
        max-width:calc(100% - 53px);
    }

    .deliveryContents .box1 > .service .row4 .payStatus{
        display: block;
        margin:8px 0 0 0;
        font-size: 13px;
        font-weight: 600;
    }

    .deliveryContents .histories > .history .status{font-size: 15px}

    .bottomFixedButton{width: 162px}

    .bottomFixedButton2{width:180px !important}

    .deliveryContents .blueBox .address{
        font-size: 14px;
        line-height: 22px;
    }

    .payBox{padding:13px 14px;}

    .payBox .label{font-size: 13px;}
    .payBox .number{font-size: 13px;}
    /*?���?/배송?���? : ?��*/



    /*?��?��?��?�� : ?��?��*/
    .deliveryContents .box1 > .service .serviceNow .button{
        width: 50px;
        min-width: 80px;
        padding:0 9px;
        font-size: 11px;
    }

    .deliveryContents .box1 > .service .serviceNow{
        margin:8px 0 0;
    }

    .deliveryContents .box1 > .service .row4{
        margin:6px 0 0;
    }
    /*?��?��?��?�� : ?��*/



    /*?��비스 �?�? : ?��?��*/
    .deliveryContents .box1 > .service .serviceNow .serviceNowTitle{
    }

    .otherService{padding:13px 14px;}
    /*?��비스 �?�? : ?��*/

    .paymentHistory{padding:13px 14px;}
    .deliveryOne{padding:13px 14px;}
    .addressOne{padding:13px 14px;}

    .homeHeader .homeLogo{
        width: 120px;
        margin:14px 0 0;
    }

} /*반응?�� 351px ?��*/


@media screen and (max-width: 333px) {

} /*반응?�� 333px ?��*/


@media screen and (max-width: 322px) {
    header h1{
        font-size: 15px;
    }

    .mainTopMenus .mainTopMenu{font-size: 12px;}

    .services .service .col2 > .txt1{font-size: 13px;}
    .services .service .col2 > .txt2{
        font-size: 12px;
    }

    .cBox .cbTxt1{
        font-size: 16px;
    }
    .cBox .cbTxt3{
        font-size: 13px;
    }

    .payBox .changeDefaultBtn {
        width: 140px;
        font-size: 12px;
    }

    .deliveryContents .box1 > .service .serviceNow .serviceNowTitle{
        font-size: 14px;
        max-width:calc(100% - 53px);
    }
    .deliveryContents .box1 > .service .row4 .serviceDate{
        font-size: 11px;
    }
    .deliveryContents .box1 > .service .row4 .payDate{
        font-size: 11px;
    }

    .service > .row5 > .payment{
        font-size: 12px;
    }
    
    .service > .row6{
        font-size: 12px;
    }

    .deliveryContents .box1 > .service .serviceNow .button{
        height: 22px;
        margin-top:-2px;
    }

    .dataWrap .bw{
       width: 12px;
       
    }

    .deliveryButton {
        margin-left: 0px;
        margin-bottom: -6px;
    }

    .deliveryButton2 {
        margin-top: 10px;
    }

} /*반응?�� 322px ?��*/



@media screen and (max-width: 300px) {

    .login .centerLogo{
        top:calc(50% - 110px);
        max-width: 190px;
    }


    .login > .snsLogins .snsLogin + .snsLogin{margin:0 0 0 10px}

    /* 2024.04.08 추�?? */
    .contents > .centerWrap{padding: 120px 15px 100px}

    /*결제?��?�� : ?��?��*/
    .paymentHistory .payment .t2{
        width: 100%;
        text-align: right;
        float: right;
        margin:3px 0 0;
    }
    /*결제?��?�� : ?��*/

    .mainTopMenus .mainTopMenu{font-size: 11px;}

    .services .service .col2 > .txt1{font-size: 11px;}
    .services .service .col2 > .txt2{
        font-size: 10px;
    }

    .deliveryContents .box1 > .service .serviceNow .serviceNowTitle{
        font-size: 14px;
        max-width:calc(100% - 45px);
    }

    .cBox .cbTxt1{
        font-size: 15px;
    }
    .cBox .cbTxt3{
        font-size: 11px;
    }

    .deliveryContents .box1 > .service .serviceNow{
        padding:13px 8px;
    }

    .service > .row5 > .payment{
        font-size: 11px;
    }
    
    .service > .row6{
        font-size: 11px;
    }

} /*반응?�� 300px ?��*/


/* body ?��그에 직접 class�? ?���? 못하�? ?��문에 div ?��그�?? 만들�? 거기?�� css�? ?��?��?��?��?��. */
.loginBody {
    width: 100%;
    position: relative;
    word-wrap: break-word;
    word-break: break-all;
    min-width: 280px;
    min-height: 100vh;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.verifyWarning {
    display: none;
}

/* .swal2-modal {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
} */

.sdStatus {
    position: relative;
    color: red;
    left: 10px;
    font-size: 20px;
    line-height: 20px;
}

.disabledWrap {
    /* background-color: rgb(204, 204, 204); */
    color: #737272;
}

/*
2024.04.04 추�??
*/
.priceWrap > .row > .originalPrice {
    display: block;
    width: 100%;
    color: #C4C4C4 !important;
    font-weight: 400;
    font-size: 11px;
    text-decoration: line-through;
    margin: 4px 0 0;
    text-align: right;
}

.txtB2 .txt:before {
    content: "";
    display: block;
    position: relative;
    z-index: 10;
    left: 0;
    top: 6px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
}

/* .txtB2 .txt {
    display: block;
    float: right;
    width: calc(100% - 12px);
    text-align: left;
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
} */

.cdBtn {
    display: block;
    text-align: center;
    min-width: 80px;
    height: 28px;
    line-height: 24px;
    border: 2px solid #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 14px;
    color: #fff !important;
    padding: 0 11px;
    background-color: #0064ff;
}

.underline {
    text-decoration: underline;
}

/*
2024.04.17 추�??
FAQ 카테고리 버튼 ?��?��
*/
.cateArea {
    display: flex;
    justify-content: space-between;
}

.cateArea .cateBtn {
    width: 80px;
    height: 30px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: black;
    border-radius: 15px;
    border: 2px solid black;
    margin: 14px 0 0;
    cursor: pointer;
}

.cateArea .cateBtn.active {
    width: 80px;
    height: 30px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    border-radius: 15px;
    border: 2px solid black;
    margin: 14px 0 0;
    cursor: pointer;
    background-color: var(--main);
}

.arrow_left {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    top: 7px;
    background: url(../images/btn-prev-a12.png) center no-repeat;
    background-size: 8px 20px;
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}

/*
2024.04.25 추�??
?��?���? 컨테?��?�� css 추�??
*/
#popup {
    display: flex;
    flex-direction: column;
}

/*
* 2025.02.04 �߰�
* �̿볻�� - ���� ���� �� ���� ��ư ������ ����
* sweetAlert2 ���â ���� ũ�� ����
*/
.row > .scBtn {
    width: 136px !important;
}
@media (max-width: 344px) {
    .row > .scBtn {
        width: 108px !important;
    }
}

/* div:where(.swal2-container) .swal2-html-container {
    font-size: 1em !important;
} */